import React from "react"

const ElternInfo_ContentHeader = ({headline, motto}) => (
  <section id="content_1">
    <h1 className="headline">{headline}</h1>

    <h2 className="subhead">{motto}</h2>

    <div style={{marginTop: "40px", marginBottom: "40px"}}>
      <hr />
    </div>
  </section>
)

export default ElternInfo_ContentHeader
