import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TermineMargin from "../../components/termine-margin"
import ElternInfoContentHeader from "../../components/elterninfo/elterninfo_contentHeader"
import ElternInfoSubmenu from "../../components/elterninfo/elterninfo_submenu"

const kinderseiten = () => (
  <Layout>
    <SEO title="Kinderseiten - Spielen, basteln, Spaß und Spannung"/>

    <section id="content_2_schule">
      <ElternInfoContentHeader headline="KINDERSEITEN" motto="SPIELEN, BASTELN, SPASS UND SPANNUNG"/>
      <ElternInfoSubmenu/>

      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{color:"#007c34"}}>TOLLE INTERNETSEITEN F&uuml;R KINDER & ELTERN</span></h3>

        <p className="text"><span style={{fontWeight: "bold"}}>Liebe Eltern, wir sprechen mit diesen Internetseiten einige Empfehlungen aus. Diese Seiten sind aus unserer Sicht f&uuml;r Kinder empfehlenswert und auch p&auml;dagogisch unbedenklich.</span><br/><br/>
          <h4 className="xsmall_sub">Seiten f&uuml;r Kinder</h4>
        </p>
        <p className="text">
          <ul>
            <li className="aufzaehlung_gruener_kreis">www.blinde-kuh.de</li>
            <li className="aufzaehlung_gruener_kreis">www.fragfinn.de</li>
            <li className="aufzaehlung_gruener_kreis">www.antolin.de</li>
            <li className="aufzaehlung_gruener_kreis">www.wasistwas.de</li>
            <li className="aufzaehlung_gruener_kreis">www.bmu-kids.de</li>
            <li className="aufzaehlung_gruener_kreis">www.kinderbrockhaus.de</li>
            <li className="aufzaehlung_gruener_kreis">www.hamsterkiste.de</li>
            <li className="aufzaehlung_gruener_kreis">www.geo.de/GEOlino</li>
            <li className="aufzaehlung_gruener_kreis">www.internet-abc.de/kinder</li>
          </ul>
          <br/>
          <h4 className="xsmall_sub">Seiten f&uuml;r Eltern</h4>

          <ul>
            <li className="aufzaehlung_gruener_kreis">www.schau-hin.info</li>
            <li className="aufzaehlung_gruener_kreis">www.internet-abc.de</li>
            <li className="aufzaehlung_gruener_kreis">www.flimmo.tv</li>
            <li className="aufzaehlung_gruener_kreis">www.klicksafe.de</li>
            <li className="aufzaehlung_gruener_kreis">www.spieleratgeber-nrw.de</li>
          </ul>
        </p>
      </article>

      <TermineMargin/>
    </section>
  </Layout>
)

export default kinderseiten