import React from "react"
import { Link } from "gatsby"
import TerminMarginEntry from "./termine-margin-entry"
import { StaticQuery, graphql } from "gatsby"

const now = Date.now();

function formatDate(date) {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  return new Intl.DateTimeFormat('de-DE', options).format(date);
}

const render = () => {
  return (
    <StaticQuery
      query={graphql`
          query terminQuery {
          allSanityTermin(sort: {fields: date, order: ASC}) {
            nodes {
              title
              description
              date
              id
            }
          }
        }
      `}
      render={data => (
        <article id="aktuelles">
          <div id="termineTitle">
            <h3 className="small_sub">AKTUELLES</h3>
            <div id="linie_date_white"></div>
          </div>
          {data.allSanityTermin.nodes
            .filter((item) => new Date(item.date) >= now)
            .slice(0,3)
            .map((item) =>
            <TerminMarginEntry key={item.id} title={item.title} dateStr={formatDate(new Date(item.date))} description={item.description} />
          )}

          <Link to="/elterninfo/termine" className="mehr">... mehr Termine</Link>
        </article>
      )}
    />
  )
}

export default render
