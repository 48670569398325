import React from "react"
import { Link } from "gatsby"

const elterninfo_submenu = () => (

  <nav className="nav_schule">
    <ul>
      <li><Link to="/elterninfo/termine" className="menu">AKTUELLE TERMINE</Link></li>
      <li><Link to="/elterninfo/betreuung" className="menu">BETREUUNGSGRUPPE</Link></li>
      <li><Link to="/elterninfo/ganztagsangebot" className="menu">GANZTAGSANGEBOT</Link></li>
      <li><Link to="/elterninfo/steckbriefe" className="menu">SCHULSOZIALARBEIT</Link></li>
      <li><Link to="/elterninfo/wichtigelinks" className="menu">WICHTIGE LINKS</Link></li>
    </ul>
    <div className="shadow_links"></div>
  </nav>
)

export default elterninfo_submenu
